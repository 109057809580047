(function () {
  'use strict';

  class EditGroupsCtrl {
    constructor($mdDialog, $mdToast, Groups, EditGroups, Classrooms) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.$mdToast = $mdToast;
      vm.Groups = Groups;
      vm.Classrooms = Classrooms;
      vm.group = angular.copy(EditGroups.getObj().group);
      vm.usernameRegex = /^[a-z0-9]+$/;
      vm.classroomsIndex = EditGroups.getObj().classroomsIndex;
      vm.init();
    }
    init() {
      const vm = this;
      vm.getClassroom();
      vm.getGroup();
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    getGroup() {
      const vm = this;
      vm.Groups.groupIndex({id: vm.group.id})
        .then((data)=> {
          vm.group = data.group;
          vm.group.classroom_id = vm.group.classroom.id;
          const students = vm.group.students;
          vm.group.student = {
            username: students.length > 0 ? students[0].username : ''
          };
        });
    }
    getClassroom() {
      const vm = this;
      vm.Classrooms.classroomsIndex()
        .then((data)=> {
          vm.classroomsIndex = data;
        });
    }
    hide(data) {
      let vm = this;
      vm.$mdDialog.hide(data);
    }
    submit(form) {
      let vm = this;
      if (form.$valid) {
        vm.Groups.groupEdit(vm.group)
          .then((data)=> {
            vm.hide(data);
          })
          .catch((response) => {
            const {data: {
              message
            }} = response;
            vm.message(message);
          });
      }
    }
    message(msg) {
      let vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:EditClassroomsCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('EditGroupsCtrl', EditGroupsCtrl);
}());
